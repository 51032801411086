import PropTypes from "prop-types"
import React, { memo } from "react"
import { Text } from "atomize"
import { theme } from "../settings/config"

TitleText.propTypes = {
    children: PropTypes.any,
    textColor: PropTypes.string,
    textWeight: PropTypes.string,
}

TitleText.defaultProps = {
    textColor: theme.colors.gunmetal,
    textWeight: "bold",
}

function TitleText({ children, textWeight, ...others }) {
    return (
        <Text
            textSize={{
                xs: "heading",
                md: "display1",
                lg: "display2",
            }}
            textWeight={textWeight}
            {...others}
        >
            {children}
        </Text>
    )
}

export default memo(TitleText)
